import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUser, updateUserByAPI } from "../api/user";
import {
  StyledSubmit,
  StyledForm,
  InputRow2,
  InputRow3,
  StyledLabel,
  StyledInput,
  StyledCancelButton,
  StyledErrorResponse,
} from "../utils/styled";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { stateOptions } from "../utils/helpers";
import { CompanySelect } from "../components/CompanySelect";

function UpdateProfile() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    company_id: "",
    company_name: "",
    occupation: "",
    memberID: "",
    user_id: "",
    original_email: "",
  });

  const navigate = useNavigate();

  const handleStateChange = (selectedOption) => {
    setFormValues((prev) => ({ ...prev, state: selectedOption?.value || "" }));
  };

  const getTheUser = async () => {
    setLoading(true);
    const fetchedUser = await getUser();
    setUser(fetchedUser);
    setLoading(false);
  };

  useEffect(() => {
    if (user && user.id) {
      setFormValues({
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        address: user.address || "",
        address2: user.address2 || "",
        city: user.city || "",
        state: user.state || "",
        zip: user.zip || "",
        phone: user.phone || "",
        email: user.email || "",
        company_id: user.company_id || "",
        company_name: user.company_name || "",
        occupation: user.occupation || "",
        memberID: user.memberID || "",
        user_id: user.id,
        original_email: user.email,
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") validateEmail(value);
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setFormValues((prev) => ({ ...prev, phone: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setEmailError("");

    if (!validateEmail(formValues.email)) {
      setLoading(false);
      return;
    }

    const updatedUser = await updateUserByAPI(formValues);
    setLoading(false);
    if (updatedUser.error === 409) {
      setFormValues((prev) => ({
        ...prev,
        email: prev.original_email,
      }));
      return setEmailError("Email already in use");
    }
    navigate("/details");
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  useEffect(() => {
    getTheUser();
  }, []);

  let content = "loading user...";

  if (!user?.id && !Array.isArray(user) && !loading) {
    content = (
      <article>
        <h1>Update Profile</h1>
        <p>{user?.message || "User Not Found"}</p>
      </article>
    );
  }

  if (user.id) {
    content = (
      <article>
        <h1>Update Profile</h1>
        <StyledForm onSubmit={handleFormSubmit}>
          <div>
            <StyledLabel htmlFor="memberID">Member ID</StyledLabel>
            <StyledInput
              type="text"
              name="memberID"
              id="memberID"
              value={formValues.memberID}
              disabled
            />
          </div>
          <InputRow2>
            <div>
              <StyledLabel htmlFor="occupation">Occupation</StyledLabel>
              <StyledInput
                type="text"
                name="occupation"
                id="occupation"
                value={formValues.occupation}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <StyledLabel htmlFor="company_id">Company</StyledLabel>
              <CompanySelect
                value={
                  formValues.company_id
                    ? {
                        value: formValues.company_id,
                        label: formValues.company_name,
                      }
                    : null
                }
                onChange={(selected) =>
                  setFormValues((prev) => ({
                    ...prev,
                    company_id: selected?.value || "",
                    company_name: selected?.label || "",
                  }))
                }
              />
            </div>
          </InputRow2>
          <InputRow2>
            <div>
              <StyledLabel htmlFor="first_name">First Name</StyledLabel>
              <StyledInput
                type="text"
                name="first_name"
                id="first_name"
                value={formValues.first_name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <StyledLabel htmlFor="last_name">Last Name</StyledLabel>
              <StyledInput
                type="text"
                name="last_name"
                id="last_name"
                value={formValues.last_name}
                onChange={handleInputChange}
                required
              />
            </div>
          </InputRow2>

          <div>
            <StyledLabel htmlFor="address">Address</StyledLabel>
            <StyledInput
              type="text"
              name="address"
              id="address"
              value={formValues.address}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <StyledLabel htmlFor="address2">Address 2</StyledLabel>
            <StyledInput
              type="text"
              name="address2"
              id="address2"
              value={formValues.address2}
              onChange={handleInputChange}
            />
          </div>

          <InputRow3>
            <div>
              <StyledLabel htmlFor="city">City</StyledLabel>
              <StyledInput
                type="text"
                name="city"
                id="city"
                value={formValues.city}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <StyledLabel htmlFor="state">State</StyledLabel>
              <Select
                id="state"
                name="state"
                options={stateOptions}
                value={stateOptions.find(
                  (opt) => opt.value === formValues.state
                )}
                onChange={handleStateChange}
                isSearchable
                required
              />
            </div>
            <div>
              <StyledLabel htmlFor="zip">Zip</StyledLabel>
              <StyledInput
                type="text"
                name="zip"
                id="zip"
                value={formValues.zip}
                onChange={handleInputChange}
                required
              />
            </div>
          </InputRow3>

          <InputRow2>
            <div>
              <StyledLabel htmlFor="phone">Phone</StyledLabel>
              <PhoneInput
                country="us"
                value={formValues.phone}
                onChange={handlePhoneChange}
              />
            </div>
            <div>
              <StyledLabel htmlFor="email">Email</StyledLabel>
              <StyledInput
                type="email"
                name="email"
                id="email"
                value={formValues.email}
                onChange={handleInputChange}
                required
              />
              <StyledErrorResponse>{emailError}</StyledErrorResponse>
            </div>
          </InputRow2>

          <input type="hidden" name="original_email" value={formValues.email} />
          <input type="hidden" name="user_id" value={formValues.user_id} />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <StyledSubmit disabled={loading}>
              {loading ? "Updating Profile..." : "Update Profile"}
            </StyledSubmit>
            {!loading ? (
              <StyledCancelButton onClick={handleCancel}>
                Cancel
              </StyledCancelButton>
            ) : (
              ""
            )}
          </div>
        </StyledForm>
      </article>
    );
  }

  return <main className="member-page">{content}</main>;
}

export default UpdateProfile;
