import React from "react";
import AsyncSelect from "react-select/async";
import { findOrganization } from "../api/user";
import { debounce } from "../utils/helpers";

const loadOptions = debounce(async (inputValue, callback) => {
  if (inputValue.length < 2) {
    return callback([]);
  }
  try {
    const results = await findOrganization(inputValue);
    callback(results);
  } catch (error) {
    callback([]);
  }
}, 500);

export const CompanySelect = ({ value, onChange }) => (
  <AsyncSelect
    value={value}
    onChange={onChange}
    loadOptions={loadOptions}
    defaultOptions={value ? [value] : []}
    cacheOptions={false}
    placeholder="Search for company..."
  />
);
