function MemberDetails(props) {
  const user = props.user;

  return (
    <article className="memberDetails">
      {!props.hideTitle ? <h3>Member Details</h3> : ""}
      <div>
        <p>{user.name || ""}</p>
        <p>{user.address || ""}</p>
        {user.hasOwnProperty("address2") ? <p>{user.address2}</p> : ""}
        <p>
          {user.city || ""}, {user.state || ""} {user.zip || ""}
        </p>
        <p>{user.memberID ? `#${user.memberID}` : "No Member ID found"}</p>
      </div>
    </article>
  );
}

export default MemberDetails;
