import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUser, setStoredUser } from "../api/user";
import RecentContributions from "../components/RecentContributions";
import ContributionTotals from "../components/ContributionTotals";
import MemberDetails from "../components/MemberDetails";
import ContributionAmountSelector from "../components/ContributionAmountSelector";
import { getConfig } from "../utils/helpers";
import { StyledButton } from "../utils/styled";
import { useNavigate } from "react-router-dom";

function GenerateTransaction() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const getTheUser = async () => {
    setLoading(true);
    setUser(await getUser());
    setLoading(false);
  };

  const instanceConfig = getConfig();

  const setCurrentUser = (userID) => {
    const currentUser =
      user.filter((person) => person.id === userID)[0] ?? false;
    setStoredUser(currentUser ?? "");
    setUser(currentUser);
  };

  useEffect(() => {
    getTheUser();
    return () => {};
  }, [setUser, setLoading]);

  let content = "loading user...";

  const navigate = useNavigate();

  if (!user?.id && !Array.isArray(user) && !loading) {
    content = (
      <article>
        <h1>Make a Contribution</h1>
        <p>{user?.message || "User Not Found"}</p>
      </article>
    );
  }

  if (Array.isArray(user)) {
    const personContent = user.map((person, key) => (
      <li key={key}>
        <input
          type="radio"
          name="multiple-user-select"
          id={"multiple-user-select-" + key}
          value={person.id}
          onClick={() => setSelectedUser(person.id)}
        />
        <label htmlFor={"multiple-user-select-" + key}>
          <MemberDetails user={person} hideTitle={true} />
        </label>
      </li>
    ));
    content = (
      <article>
        <h1>Multiple Members Found</h1>
        <ul id="multiple-member-list">{personContent}</ul>

        <StyledButton
          type="button"
          onClick={() => setCurrentUser(selectedUser)}
          className={!selectedUser ? "disabled" : ""}
        >
          Proceed to Contribution Screen
        </StyledButton>
      </article>
    );
  }

  if (user.id) {
    content = (
      <article>
        <h1>Make a Contribution</h1>
        <div className="cols-2">
          <div>
            <MemberDetails user={user} />
            <StyledButton
              type="button"
              style={{ marginTop: "1rem" }}
              onClick={() => navigate("/update-profile")}
            >
              Update Profile
            </StyledButton>
          </div>
          <RecentContributions contributions={user.contributions.recent} />
        </div>

        <hr />

        <ContributionTotals contributions={user.contributions} />

        <hr />

        {instanceConfig.cap && user.cap <= 0 ? (
          <p>{instanceConfig.capMsg}</p>
        ) : (
          <ContributionAmountSelector />
        )}
      </article>
    );
  }

  return (
    <>
      <main className="member-page">{content}</main>
      <Link to="/" className="start-over-link">
        Start Over
      </Link>
    </>
  );
}

export default GenerateTransaction;
