import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateUserByAPI } from "../api/user";
import {
  StyledSubmit,
  StyledForm,
  InputRow2,
  InputRow3,
  StyledLabel,
  StyledInput,
  StyledCancelButton,
  StyledErrorResponse,
} from "../utils/styled";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { stateOptions } from "../utils/helpers";
import { CompanySelect } from "../components/CompanySelect";

function AddUser() {
  const [emailError, setEmailError] = useState("");
  const [memberError, setMemberError] = useState("");
  const [genericError, setGenericError] = useState("");
  const [loading, setLoading] = useState(false);

  const defaultFormValues = {
    first_name: "",
    last_name: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    company_id: "",
    company_name: "",
    occupation: "",
    memberID: "",
    add_user: true,
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const navigate = useNavigate();

  const handleStateChange = (selectedOption) => {
    setFormValues((prev) => ({ ...prev, state: selectedOption?.value || "" }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") validateEmail(value);
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (value) => {
    setFormValues((prev) => ({ ...prev, phone: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setEmailError("");
    setMemberError("");
    setGenericError("");

    if (!validateEmail(formValues.email)) {
      setLoading(false);
      return;
    }

    try {
      const updatedUser = await updateUserByAPI(formValues);

      if (updatedUser.error) {
        switch (updatedUser.message) {
          case "Email already in use":
            setEmailError(updatedUser.message);
            break;
          case "Member ID already in use":
            setMemberError(updatedUser.message);
            break;
          default:
            setGenericError(updatedUser.message || "Error creating user");
            break;
        }
      } else {
        navigate("/details");
      }
    } catch (error) {
      setGenericError("Error creating user");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  return (
    <main className="member-page">
      <article>
        <h1>Create Member</h1>
        <StyledErrorResponse>{genericError}</StyledErrorResponse>
        <StyledForm onSubmit={handleFormSubmit}>
          <div>
            <StyledLabel htmlFor="memberID">Member ID</StyledLabel>
            <StyledInput
              type="text"
              name="memberID"
              id="memberID"
              value={formValues.memberID}
              onChange={handleInputChange}
            />
            <StyledErrorResponse>{memberError}</StyledErrorResponse>
          </div>
          <InputRow2>
            <div>
              <StyledLabel htmlFor="occupation">Occupation</StyledLabel>
              <StyledInput
                type="text"
                name="occupation"
                id="occupation"
                value={formValues.occupation}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <StyledLabel htmlFor="company_id">Company</StyledLabel>
              <CompanySelect
                value={
                  formValues.company_id
                    ? {
                        value: formValues.company_id,
                        label: formValues.company_name,
                      }
                    : null
                }
                onChange={(selected) =>
                  setFormValues((prev) => ({
                    ...prev,
                    company_id: selected?.value || "",
                    company_name: selected?.label || "",
                  }))
                }
              />
            </div>
          </InputRow2>
          <InputRow2>
            <div>
              <StyledLabel htmlFor="first_name">First Name</StyledLabel>
              <StyledInput
                type="text"
                name="first_name"
                id="first_name"
                value={formValues.first_name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <StyledLabel htmlFor="last_name">Last Name</StyledLabel>
              <StyledInput
                type="text"
                name="last_name"
                id="last_name"
                value={formValues.last_name}
                onChange={handleInputChange}
                required
              />
            </div>
          </InputRow2>

          <div>
            <StyledLabel htmlFor="address">Address</StyledLabel>
            <StyledInput
              type="text"
              name="address"
              id="address"
              value={formValues.address}
              onChange={handleInputChange}
              required
            />
          </div>

          <div>
            <StyledLabel htmlFor="address2">Address 2</StyledLabel>
            <StyledInput
              type="text"
              name="address2"
              id="address2"
              value={formValues.address2}
              onChange={handleInputChange}
            />
          </div>

          <InputRow3>
            <div>
              <StyledLabel htmlFor="city">City</StyledLabel>
              <StyledInput
                type="text"
                name="city"
                id="city"
                value={formValues.city}
                onChange={handleInputChange}
                required
              />
            </div>
            <div>
              <StyledLabel htmlFor="state">State</StyledLabel>
              <Select
                id="state"
                name="state"
                options={stateOptions}
                value={stateOptions.find(
                  (opt) => opt.value === formValues.state
                )}
                onChange={handleStateChange}
                isSearchable
                required
              />
            </div>
            <div>
              <StyledLabel htmlFor="zip">Zip</StyledLabel>
              <StyledInput
                type="text"
                name="zip"
                id="zip"
                value={formValues.zip}
                onChange={handleInputChange}
                required
              />
            </div>
          </InputRow3>

          <InputRow2>
            <div>
              <StyledLabel htmlFor="phone">Phone</StyledLabel>
              <PhoneInput
                country="us"
                value={formValues.phone}
                onChange={handlePhoneChange}
              />
            </div>
            <div>
              <StyledLabel htmlFor="email">Email</StyledLabel>
              <StyledInput
                type="email"
                name="email"
                id="email"
                value={formValues.email}
                onChange={handleInputChange}
                required
              />
              <StyledErrorResponse>{emailError}</StyledErrorResponse>
            </div>
          </InputRow2>

          <input type="hidden" name="add_user" value="true" />

          <div style={{ display: "flex", justifyContent: "center" }}>
            <StyledSubmit disabled={loading}>
              {loading ? "Creating Member..." : "Create Member"}
            </StyledSubmit>
            {!loading ? (
              <StyledCancelButton onClick={handleCancel}>
                Cancel
              </StyledCancelButton>
            ) : (
              ""
            )}
          </div>
        </StyledForm>
      </article>
    </main>
  );
}

export default AddUser;
