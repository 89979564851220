import styled from "styled-components";
import { getConfig } from "../utils/helpers";

const instanceConfig = getConfig();

const StyledSubmit = styled.button.attrs((props) => ({
  type: "submit",
  className: "submit-btn",
}))`
  background-color: ${instanceConfig.color || "hsl(218deg 75% 46%)"};
  cursor: pointer;
  &:disabled {
    background: transparent;
    color: #777;
    box-shadow: none;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const StyledButton = styled.button.attrs((props) => ({
  className: "submit-btn",
}))`
  background-color: ${instanceConfig.color || "hsl(218deg 75% 46%)"};
  cursor: pointer;
  &:disabled {
    background: transparent;
    color: #777;
    box-shadow: none;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const InputRow2 = styled.div`
  display: grid;
  gap: 1rem;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr;
`;

const InputRow3 = styled.div`
  display: grid;
  gap: 1rem;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr;
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: 0.25rem;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #cacaca;
  border-radius: 5px;
`;

const StyledCancelButton = styled.button.attrs((props) => ({
  type: "button",
}))`
  background-color: transparent;
  padding: 20px;
  color: #000;
  border: 2px solid #fff;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
`;

const StyledErrorResponse = styled.p`
  color: red;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  &:empty {
    display: none;
  }
`;

export {
  StyledButton,
  StyledSubmit,
  StyledForm,
  InputRow2,
  InputRow3,
  StyledLabel,
  StyledInput,
  StyledCancelButton,
  StyledErrorResponse,
};
